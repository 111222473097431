import Vue, { PropType } from "vue";

export const ISelectDateIntervalProp = Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
    onSelect: {
      type: Function as PropType<(selected: string) => void>,
      required: true,
    },
  },
});
