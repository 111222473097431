
import Vue from "vue";
import Component from "vue-class-component";

import ActionIconButton from "@/components/lib/button/ActionIconButton.vue";
import DefaultSelect from "@/components/lib/select/DefaultSelect.vue";
import {
  IconDefinition,
  faVolumeUp,
  faVolumeMute,
  faVolumeDown,
  faRedoAlt,
  faUndoAlt,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import * as utils from "@/services/utils";
import { StoreActionTypes } from "@/store/types";
import PlayerSpeedControls from "@/components/lib/player/PlayerSpeedControls.vue";
import { ActionIconBtnText } from "../button/IActionIconButton";

@Component({
  components: {
    ActionIconButton,
    DefaultSelect,
    PlayerSpeedControls,
  },
})
export default class PlayerControls extends Vue {
  private componentClass = "PlayerControls";

  private playBtn: IconDefinition = faPlay;
  private pauseBtn: IconDefinition = faPause;
  private volumeUpBtn: IconDefinition = faVolumeUp;
  private volumeDownBtn: IconDefinition = faVolumeDown;
  private volumeMuteBtn: IconDefinition = faVolumeMute;
  private forwardBtn: IconDefinition = faRedoAlt;
  private backwardBtn: IconDefinition = faUndoAlt;

  private actionBtnText: ActionIconBtnText = {
    text: "10",
    color: "primary",
  };

  get playIcon(): IconDefinition {
    return this.$store.getters.isPlayerPlay ? this.pauseBtn : this.playBtn;
  }

  get volumeIcon(): IconDefinition {
    const volume = this.$store.getters.getPlayerVolume;

    if (volume == 10) {
      return this.volumeUpBtn;
    } else if (volume == 0) {
      return this.volumeMuteBtn;
    } else {
      return this.volumeDownBtn;
    }
  }

  get getVolume() {
    return this.$store.getters.getPlayerVolume * 10;
  }

  handleVolumeProgressBarClick(event: Event) {
    const mouseEvent = event as MouseEvent;
    mouseEvent.preventDefault();
    const volume = Math.round(
      (mouseEvent.offsetX * 10) /
        (this.$refs.volumeProgressBar as HTMLDivElement).getBoundingClientRect()
          .width
    );
    this.$store.dispatch(StoreActionTypes.SET_PLAYER_VOLUME, volume);
  }

  get getDuration(): string {
    return utils.renderDurationWithDots(
      this.$store.getters.getPlayerDuration * 1000
    );
  }

  get getCurrentTime(): string {
    return utils.renderDurationWithDots(
      this.$store.getters.getPlayerCurrentTime * 1000
    );
  }

  handlePlayPauseBtnClick() {
    this.$store.dispatch(
      StoreActionTypes.SET_PLAYER_PLAY,
      !this.$store.getters.isPlayerPlay
    );
  }

  handleVolumeIconClick() {
    const volume = this.$store.getters.getPlayerVolume;
    if (volume > 0) {
      this.$store.dispatch(StoreActionTypes.SET_PLAYER_VOLUME, 0);
    } else {
      this.$store.dispatch(StoreActionTypes.SET_PLAYER_VOLUME, 10);
    }
  }

  playBackBtnClick() {
    const currentTime = this.$store.getters.getPlayerCurrentTime;
    const newTime = currentTime - 10 > 0 ? currentTime - 10 : 0;
    this.$store.dispatch(StoreActionTypes.SET_PLAYER_NEW_CURRENT_TIME, newTime);
  }

  playForwardBtnClick() {
    const currentTime = this.$store.getters.getPlayerCurrentTime;
    const duration = this.$store.getters.getPlayerDuration;
    const newTime = currentTime + 10 < duration ? currentTime + 10 : duration;
    this.$store.dispatch(StoreActionTypes.SET_PLAYER_NEW_CURRENT_TIME, newTime);
  }
}
