
import Component from "vue-class-component";
import DefaultSelect from "@/components/lib/select/DefaultSelect.vue";
import { CriterionTypeCode, SelectModel } from "@/services/types";
import { ISelectCriterionTypeFilterProp } from "./ISelectCriterionTypeFilter";
import * as utils from "@/services/utils";

@Component({
  components: {
    DefaultSelect,
  },
})
export default class SelectCriterionType extends ISelectCriterionTypeFilterProp {
  private componentClass = "SelectCriterionType";

  private typeSelectOptions: SelectModel[] = [
    {
      value: "CUSTOM",
      text: utils.getCriterionTypeCode("CUSTOM"),
    },
    {
      value: "PROHIBITION",
      text: utils.getCriterionTypeCode("PROHIBITION"),
    },
    {
      value: "REQUIREMENT",
      text: utils.getCriterionTypeCode("REQUIREMENT"),
    },
  ];

  isActive(value: string) {
    return this.value == value;
  }

  get getTitle(): string {
    return utils.getCriterionTypeCode(this.value as CriterionTypeCode);
  }
}
