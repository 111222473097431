
import Component from "vue-class-component";
import { DefaultSelectProps } from "./IDefaultSelect";

@Component
export default class GridItemQuantitySelect extends DefaultSelectProps {
  private componentClass = "GridItemQuantitySelect";

  listener(event: string) {
    this.onChange(event);
  }

  isActive(value: string) {
    return value == this.selectedItem;
  }
}
