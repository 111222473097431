import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export class Modal2ComponentProps extends Vue {
  @VModel({ type: Boolean, default: false }) showModal!: boolean;

  @Prop(String) protected title: string;
  @Prop(String) protected subtitle!: string;
  @Prop(String) protected okTitle!: string;
  @Prop(String) protected cancelTitle!: string;
  @Prop(String) protected classModificator!: boolean;
  @Prop(String) protected actionText!: boolean;
  @Prop(String) protected actionTextTop!: boolean;

  @Prop({ default: false, type: Boolean }) protected isBusy!: boolean;
  @Prop({ default: false, type: Boolean }) protected disabled!: boolean;
  @Prop({ default: false, type: Boolean }) protected hideFooter!: boolean;

  @Emit()
  okCallback(BvModalEvent) {
    BvModalEvent.preventDefault();
    return BvModalEvent;
  }

  @Emit()
  customAction(e) {
    return e;
  }

  @Emit()
  customActionTop(e) {
    return e;
  }
}
