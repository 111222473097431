
import Component from "vue-class-component";
import Vue from "vue";
import FormInput from "./../input/FormInput.vue";
import ActionButton from "./../button/ActionButton.vue";
import * as utils from "@/services/utils";
import { renderDurationWithDots } from "@/services/utils";
import { faClock, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { CreateCommentRequestModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { i18n } from "@/i18n/index";
import { usePlayerStore } from "@/pinia/player";
import ActionIconButton from "@/components/lib/button/ActionIconButton.vue";
import { getStore } from "@/store/getStore";
import { CreateEntityCommentRequestDto } from "@/services/api/entities/types";
import { showErrorMessage } from "@/services/ui/messages";

@Component({
  methods: { renderDurationWithDots },
  components: {
    ActionIconButton,
    FormInput,
    ActionButton,
  },
  watch: {
    "$store.getters.getPlayerCurrentTime": "watchPlayerCurrentTime",
    "$store.getters.getCommentsSelectedCommentId":
      "watchCommentsSelectedCommentId",
  },
})
export default class CreateNewCommentForm extends Vue {
  private componentClass = "CreateNewCommentForm";
  private placeholder = i18n.t("calls.comment.add");
  private showTextarea = false;
  private value = "";
  private currentTimeValue = "00:00";
  private timeIcon: IconDefinition = faClock;

  private currentTimeValueIsValid = true;

  timestampEnabled = true;

  get playerData() {
    return usePlayerStore().lastPlayed;
  }

  handleFormInputClick() {
    this.showTextarea = true;
    this.$store.dispatch(StoreActionTypes.SET_COMMENTS_SELECTED_COMMENT_CLEAR);
  }

  toggleTimestamp() {
    this.timestampEnabled = !this.timestampEnabled;
  }

  async handleAddBtnClick() {
    if (!this.value.length) {
      showErrorMessage(`Нельзя оставить пустой комментарий`);
      return;
    }

    const request: CreateEntityCommentRequestDto = {
      data: {
        text: this.value,
        position: this.timestampEnabled
          ? {
              attachment: this.playerData.id,
              timeOnTrack: this.playerData.time
                ? Math.round(this.playerData.time)
                : null,
            }
          : {
              attachment: null,
              timeOnTrack: null,
            },
      },
    };

    await getStore().dispatch(
      StoreActionTypes.SET_COMMENTS_CREATE_COMMENT,
      request
    );
    this.value = "";
    this.showTextarea = false;
    getStore().dispatch(
      StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT,
      false
    );
  }

  createCreateCommentRequestModel(): CreateCommentRequestModel {
    return {
      timeOnTrack:
        this.currentTimeValue.length > 0
          ? utils.getSecondsFromMmSsString(this.currentTimeValue)
          : null,
      comment: this.value,
    };
  }

  handleCancelBtnClick() {
    this.showTextarea = false;
    this.value = "";
    this.currentTimeValue = "00:00";
    this.$store.dispatch(
      StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT,
      false
    );
  }

  watchPlayerCurrentTime(newTime: number, oldTime: number) {
    if (newTime != oldTime) {
      this.currentTimeValue = utils.renderDurationWithDots(newTime * 1000);
    }
  }

  handleCurrentTimeInputEvent(text: string) {
    this.currentTimeValueIsValid = true;
    this.currentTimeValue = text;
  }

  handleClearIconClick() {
    this.currentTimeValueIsValid = true;
    this.currentTimeValue = "00:00";
  }

  get showClearIcon() {
    return (
      this.currentTimeValue.length > 0 &&
      utils.getSecondsFromMmSsString(this.currentTimeValue)
    );
  }

  watchCommentsSelectedCommentId(id: number) {
    if (id) {
      this.showTextarea = false;
      this.$store.dispatch(
        StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT,
        false
      );
    }
  }

  validateCurrentTimeValue(): boolean {
    const duration = this.$store.getters.getPlayerDuration;

    if (duration == 0) {
      return true;
    }

    const timeOnTrack =
      this.currentTimeValue.length > 0
        ? utils.getSecondsFromMmSsString(this.currentTimeValue)
        : 0;

    if (timeOnTrack > duration) {
      this.currentTimeValueIsValid = false;
      return false;
    } else {
      this.currentTimeValueIsValid = true;
      return true;
    }
  }

  get currentTimeValueFormInputValidation(): boolean {
    return this.currentTimeValueIsValid ? null : false;
  }

  onCommentChange() {
    if (this.value.length > 0) {
      this.$store.dispatch(
        StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT,
        true
      );
    } else {
      this.$store.dispatch(
        StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT,
        false
      );
    }
  }

  get addCommentBtnDsabled(): boolean {
    return this.$store.getters.getCommentsDataIsLoading;
  }
}
