
import Component from "vue-class-component";
import { ListAnswerSwitcherProps } from "./IListAnswerSwitcher";
import { BaseEntityModel, CallCriterionEstimateModel } from "@/services/types";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import { isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";

interface OptionsRenderItem extends BaseEntityModel {
  state: boolean;
  disabled?: boolean;
}

@Component({
  computed: {
    UserPermissions() {
      return UserPermissions;
    },
  },
  methods: { isUserHasPermission },
  watch: {
    list: "handleListChanges",
    "$store.getters.getPlayerIsPlayed": "handlePlayerIsPlayed",
    "$store.getters.getSelectedCallCategories": "handleSelectedCallCategories",
  },
})
export default class ListAnswerSwitcher extends ListAnswerSwitcherProps {
  private componentClass = "ListAnswerSwitcher";
  private value: number | null = null;
  private renderList: OptionsRenderItem[] = [];

  mounted() {
    this.createRenderList();
  }

  get callIsEdited(): boolean {
    return (
      this.$store.getters.getSelectedCallAllCriterionsChecked &&
      !this.$store.getters.getSelectedCallRated
    );
  }

  createRenderList() {
    if (
      !this.callIsEdited &&
      (this.criterion.selectedOptionId ||
        this.criterion.selectedOptionId === null)
    ) {
      this.value = this.criterion.selectedOptionId;
      this.renderList = this.criterion.options
        .map((item) => ({
          ...item,
          state: item.id == this.value ? true : false,
          disabled: true,
        }))
        .sort((a, b) => {
          if (a.id && b.id && a.name.length < b.name.length) return -1;
          if (a.id && b.id && a.name.length > b.name.length) return 1;
          return 0;
        });
    } else {
      this.renderList = this.criterion.options.map((item) => ({
        ...item,
        state: false,
        // disabled: !this.$store.getters.getPlayerIsPlayed,
        disabled: false,
      }));
    }
  }

  onClick(val: number) {
    this.value = val;
    this.renderList.map((item) => {
      item.id === this.value ? (item.state = true) : (item.state = false);
    });

    this.$store.dispatch(
      StoreActionTypes.SET_SELECTED_CALL_SET_CURRENT_ESTIMATION,
      this.createCallCriterionEstimateModel()
    );
    this.$store.commit(StoreMutationTypes.SET_TOP_COLLAPSE_OPENED, false);
  }

  createCallCriterionEstimateModel(): CallCriterionEstimateModel {
    return {
      id: this.criterion.id,
      optionId: this.value,
    };
  }

  getName(name: string) {
    if (name == "_SKIP") {
      return this.$i18n.t("calls.details.checkLists.options.noMatter");
    }

    return name;
  }

  handleListChanges() {
    this.createRenderList();
  }

  handlePlayerIsPlayed() {
    this.createRenderList();
  }

  handleSelectedCallCategories() {
    this.value = null;
    // console.log('handleSelectedCallCategories')
    this.createRenderList();
  }
}
