
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Modal2ComponentProps } from "@/components/modal/Modal2ComponentProps";
import {
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/free-regular-svg-icons";

@Component({})
export default class Modal2Component extends mixins(Modal2ComponentProps) {
  private componentClass = "ModalComponent";
  private modalCloseIcon: IconDefinition = faTimesCircle;

  get getClassModificator(): string {
    return this.classModificator
      ? `${this.componentClass}__${this.classModificator}`
      : "";
  }

  get getSubTitle(): string {
    return this.subtitle;
  }

  get showWithSubtitle(): boolean {
    return this.subtitle && this.subtitle.length > 0;
  }

  get getOkTitle() {
    return this.okTitle
      ? this.okTitle
      : (this.$i18n.t("actions.save") as string);
  }

  get getCancelTitle() {
    return this.cancelTitle
      ? this.cancelTitle
      : (this.$i18n.t("actions.cancel") as string);
  }

  get disabledFromSlot() {
    return (this.$slots.default as any)[0].isOkBtnDisabled;
  }
}
