import Vue from "vue";
import { PropType } from "vue";

type FormInputType = "text" | "email" | "number" | "password" | "tel";

export const FormInputProps = Vue.extend({
  props: {
    type: {
      required: false,
      type: String as PropType<FormInputType>,
      default: "text",
    },
    value: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    onChange: {
      required: true,
      type: Function as PropType<(text: string) => void>,
    },
    required: {
      required: false,
      default: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    onEnter: {
      required: false,
      type: Function as PropType<() => void>,
    },
    autocompleteAttr: {
      required: false,
      default: "",
      type: String,
    },
    validated: {
      required: false,
      type: Boolean,
      default: null,
    },
  },
});
