
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import {
  faTimes,
  faInfoCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { CheckListWithCategoriesSimpleModel } from "@/services/types";

const IProjectToastsProps = Vue.extend({
  props: {
    deleteListener: {
      required: true,
      type: Function as PropType<(id: number) => void>,
    },
    listItems: {
      required: true,
      type: Array as PropType<CheckListWithCategoriesSimpleModel[]>,
    },
  },
});

@Component
export default class ProjectToasts extends IProjectToastsProps {
  private componentClass = "ProjectToasts";
  private toastCloseIcon: IconDefinition = faTimes;
  private toastInfoIcon: IconDefinition = faInfoCircle;
}
