
import Component from "vue-class-component";
import { BaseEntityModel } from "@/services/types";
import FormInput from "@/components/lib/input/FormInput.vue";
import { ISelectManagersMultiple } from "./ISelectManagersMultiple";

@Component({
  components: {
    FormInput,
  },
})
export default class SelectManagersMultiple extends ISelectManagersMultiple {
  private componentClass = "SelectManagersMultiple";

  private searchValue = "";
  private refactoredItemList = null;

  get getFilteredItemList(): BaseEntityModel[] {
    if (this.searchValue.length >= 1) {
      return this.itemsList.filter(
        (item) =>
          item.name
            .trim()
            .toLowerCase()
            .indexOf(this.searchValue.trim().toLowerCase()) >= 0
      );
    } else {
      return this.itemsList.flat();
    }
  }

  onSearchChange(search: string) {
    this.searchValue = search;
  }

  getLabel(model: BaseEntityModel) {
    return model.name;
  }

  handleCheckboxChange(items: BaseEntityModel[]) {
    this.onSelect(items);
  }
}
