
import Component from "vue-class-component";
import { IModalComponentProps } from "./IModalComponent";
import {
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/free-regular-svg-icons";

@Component({
  watch: {
    show: "handleShowStatusChange",
  },
})
export default class ModalComponent extends IModalComponentProps {
  private componentClass = "ModalComponent";
  private modalCloseIcon: IconDefinition = faTimesCircle;
  private showModal = false;

  get getClassModificator(): string {
    return this.classModificator
      ? `${this.componentClass}__${this.classModificator}`
      : "";
  }

  get getSubTitle(): string {
    return this.subtitle ? `: ${this.subtitle}` : "";
  }

  get showWithSubtitle(): boolean {
    return this.subtitle && this.subtitle.length > 0;
  }

  get getOkTitle() {
    return this.okTitle
      ? this.okTitle
      : (this.$i18n.t("actions.save") as string);
  }

  get getCancelTitle() {
    return this.cancelTitle
      ? this.cancelTitle
      : (this.$i18n.t("actions.cancel") as string);
  }

  get disabledFromSlot() {
    return (this.$slots.default as any)[0].isOkBtnDisabled;
  }

  handleShowStatusChange() {
    this.showModal = !this.showModal;
  }

  onOkListener(BvModalEvent) {
    BvModalEvent.preventDefault();
    this.onOk();
  }
}
