import Vue from "vue";

export const CollapseAreaProps = Vue.extend({
  props: {
    collapsed: {
      required: false,
      type: Boolean,
      default: false,
    },
    title: {
      required: true,
      type: String,
    },
  },
});
