import Vue, { PropType } from "vue";

export const ISelectEstimation = Vue.extend({
  props: {
    value: {
      type: String,
      required: true,
    },
    onSelect: {
      type: Function as PropType<(selected: string) => void>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
});
