import Vue, { PropType } from "vue";

const dummy = {};

export const IModalComponentProps = Vue.extend({
  props: {
    title: {
      required: true,
      type: String,
    },
    show: {
      required: true,
      type: Boolean,
    },
    onOk: {
      required: false,
      type: Function as PropType<() => void>,
    },
    subtitle: {
      required: false,
      type: String,
    },
    okTitle: {
      required: false,
      type: String,
    },
    cancelTitle: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    classModificator: {
      required: false,
      type: String,
    },
    hideFooter: {
      required: false,
      type: Boolean,
      default: false,
    },
    customActionTop: {
      required: false,
      type: Function as PropType<() => void>,
      default: () => dummy,
    },
    customAction: {
      required: false,
      type: Function as PropType<() => void>,
      default: () => dummy,
    },
    actionText: {
      required: false,
      type: String,
    },
    actionTextTop: {
      required: false,
      type: String,
    },
  },
});
