
import Component from "vue-class-component";
import Vue from "vue";
import { PlayBackSpeed } from "@/services/types";
import { StoreActionTypes } from "@/store/types";

@Component({
  watch: {
    "$store.getters.getPlayerUrl": "watchPlayerUrlUpdate",
    "$store.getters.isPlayerPlay": "watchPlayerPlayUpdate",
    "$store.getters.getPlayerPlayBackSpeed": "watchPlayBackSpeedUpdate",
    "$store.getters.getPlayerVolume": "watchPlayerVolumeUpdate",
    "$store.getters.getPlayerNewCurrentTime": "watchPlayerNewCurrentTime",
  },
})
export default class Player extends Vue {
  private componentClass = "Player";
  private progressBarValue = 0;
  private audio: HTMLAudioElement;

  mounted() {
    this.audio = this.$refs.player as HTMLAudioElement;
    this.audio.playbackRate = this.$store.getters.getPlayerPlayBackSpeed;
    this.audio.volume = this.$store.getters.getPlayerVolume / 100;
    this.setPlayerStop();
  }

  get getUrl() {
    return this.$store.getters.getPlayerUrl;
  }

  canPlay() {
    this.$store.dispatch(
      StoreActionTypes.SET_PLAYER_DURATION,
      Math.round(this.audio.duration)
    );
  }

  onTimeUpdate() {
    this.$store.dispatch(
      StoreActionTypes.SET_PLAYER_CURRENT_TIME,
      Math.round(this.audio.currentTime)
    );
    this.progressBarValue = this.audio.currentTime / this.audio.duration;
    let playerPlay: boolean = this.$store.getters.isPlayerPlay;
    if (this.audio.currentTime == this.audio.duration) {
      playerPlay = false;
      this.setPlayerStop();
    }
    this.$store.dispatch(StoreActionTypes.SET_PLAYER_PLAY, playerPlay);
  }

  setPlayerStop() {
    this.progressBarValue = 0;
    this.audio.currentTime = 0;
    this.audio.pause();
    this.$store.dispatch(StoreActionTypes.SET_PLAYER_PLAY, false);
  }

  seek(event: Event) {
    const mouseEvent = event as MouseEvent;
    mouseEvent.preventDefault();
    const percent =
      mouseEvent.offsetX /
      (this.$refs.progressBar as HTMLDivElement).getBoundingClientRect().width;
    this.audio.currentTime = percent * this.audio.duration;
    this.progressBarValue = percent;
  }

  watchPlayerUrlUpdate(url: string, oldUrl: string) {
    if (url != oldUrl) {
      this.setPlayerStop();
      this.$store.dispatch(StoreActionTypes.SET_PLAYER_PLAY, false);
      this.audio.load();
    }
  }

  watchPlayBackSpeedUpdate(
    playBackSpeed: PlayBackSpeed,
    oldPlayBackSpeed: PlayBackSpeed
  ) {
    if (playBackSpeed != oldPlayBackSpeed) {
      this.audio.playbackRate = playBackSpeed;
    }
  }

  watchPlayerPlayUpdate(play: boolean, oldPlay: boolean) {
    if (play != oldPlay) {
      if (play) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    }
  }

  watchPlayerVolumeUpdate(volume: number, oldVolume: number) {
    if (volume != oldVolume) {
      this.audio.volume = volume / 10;
    }
  }

  watchPlayerNewCurrentTime(newTime: number, oldTime: number) {
    if (newTime != oldTime) {
      this.setCurrentTime(newTime);
    }
  }

  public setCurrentTime(currentTime: number) {
    this.audio.currentTime = currentTime;
    this.$store.dispatch(
      StoreActionTypes.SET_PLAYER_CURRENT_TIME,
      Math.round(this.audio.currentTime)
    );
  }
}
