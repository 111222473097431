
import Component from "vue-class-component";
import { IconDefinition, faMinus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "@/components/lib/input/FormInput.vue";
import { IDurationFilterProps } from "./IDurationFilter";
import { CardPlugin } from "bootstrap-vue";
import {formatMmSsString, getSecondsFromMmSsString, secondsToMmSs} from "@/services/utils";

@Component({
  components: {
    FormInput,
  },
})
export default class DurationFilter extends IDurationFilterProps {
  private componentClass = "DurationFilter";

  private dividerIcon: IconDefinition = faMinus;

  handleMinDurationBlurEvent() {
    if (this.minDuration.length) {
      this.minDurationListener(secondsToMmSs(getSecondsFromMmSsString(this.minDuration)))
    }
  }

  handleMaxDurationBlurEvent() {
    if (this.maxDuration.length) {
      this.maxDurationListener(secondsToMmSs(getSecondsFromMmSsString(this.maxDuration)))
    }
  }
}
