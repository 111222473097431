
import Component from "vue-class-component";
import { ActionIconButtonProps } from "./IActionIconButton";

@Component
export default class ActionIconButton extends ActionIconButtonProps {
  private componentClass = "ActionIconButton";

  get disabledClass(): string {
    return this.disabled ? "disabled" : "";
  }

  handleOnClick() {
    if (!this.disabled) {
      this.listener();
    }
  }
}
