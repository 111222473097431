
import Component from "vue-class-component";
import Vue from "vue";
import PlayerControls from "./PlayerControls.vue";
import Player from "./Player.vue";
import PlayerCommentsList from "./PlayerCommentsList.vue";

@Component({
  components: {
    PlayerControls,
    Player,
    PlayerCommentsList,
  },
})
export default class PlayerComponent extends Vue {
  private componentClass = "PlayerComponent";

  get showComments(): boolean {
    return (
      this.$store.getters.getPlayerDuration > 0 &&
      this.$store.getters.getCommentsGroupedPlayerComments.length > 0
    );
  }
}
