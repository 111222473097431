
import Component from "vue-class-component";
import { BaseEntityModel } from "@/services/types";
import FormInput from "@/components/lib/input/FormInput.vue";
import { ISelectCheckListMultiple } from "./ISelectCheckListMultiple";

@Component({
  components: {
    FormInput,
  },
})
export default class SelectCheckListMultiple extends ISelectCheckListMultiple {
  private componentClass = "SelectCheckListMultiple";

  private searchValue = "";

  get getItemList(): BaseEntityModel[] {
    return this.$store.getters.getCheckListsListSimpleOnlyCheckLists;
  }

  get getFilteredItemList(): BaseEntityModel[] {
    if (this.searchValue.length >= 2) {
      return this.getItemList.filter(
        (item) =>
          item.name
            .trim()
            .toLowerCase()
            .indexOf(this.searchValue.trim().toLowerCase()) >= 0
      );
    } else {
      return this.getItemList;
    }
  }

  onSearchChange(search: string) {
    this.searchValue = search;
  }

  getLabel(model: BaseEntityModel) {
    return model.name;
  }

  handleCheckboxChange(items: BaseEntityModel[]) {
    this.onSelect(items);
  }
}
