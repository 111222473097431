import { ColorTheme } from "@/services/types";
import Vue, { PropType } from "vue";

export const IConfirmationComponentProps = Vue.extend({
  props: {
    title: {
      required: true,
      type: String,
    },
    show: {
      required: true,
      type: Boolean,
    },
    onOk: {
      required: true,
      type: Function as PropType<() => void>,
    },
    onCancel: {
      required: true,
      type: Function as PropType<() => void>,
    },
    subtitle: {
      required: false,
      type: String,
    },
    okTitle: {
      required: false,
      type: String,
    },
    okVariant: {
      required: false,
      type: String as PropType<ColorTheme>,
      default: "danger",
    },
    cancelTitle: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
});
