import Vue, { PropType } from "vue";

export const ISelectCategoryProp = Vue.extend({
  props: {
    value: {
      type: Number || null,
      required: true,
    },
    onSelect: {
      type: Function as PropType<(selected: number) => void>,
      required: true,
    },
  },
});
