import Vue from "vue";
import { PropType } from "vue";
import { ColorTheme } from "./../../../services/types";

export const ActionButtonProps = Vue.extend({
  props: {
    variant: {
      type: String as PropType<ColorTheme>,
      required: false,
      default: "primary",
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    listener: {
      type: Function,
      required: true,
    },
  },
});
