
import Component from "vue-class-component";
import { ListAnswerSwitcherWrapperProps } from "./IListAnswerSwitcherWrapper";
import ListAnswerSwitcher from "./ListAnswerSwitcher.vue";

@Component({
  components: {
    ListAnswerSwitcher,
  },
})
export default class ListAnswerSwitcherWrapper extends ListAnswerSwitcherWrapperProps {
  private componentClass = "ListAnswerSwitcherWrapper";
}
