
import Vue from "vue";
import Component from "vue-class-component";
import { SelectModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import { i18n } from "@/i18n/index";

interface RenderSelectModel extends SelectModel {
  disabled?: boolean;
}

@Component
export default class CallGridColumnsSelect extends Vue {
  private componentClass = "CallGridColumnsSelect";

  private selectedItems: RenderSelectModel[] = [];

  private list: RenderSelectModel[] = [
    {
      value: "project",
      text: i18n.t("calls.grid.project") as string,
      disabled: false,
    },
    {
      value: "manager",
      text: i18n.t("calls.grid.manager") as string,
      disabled: false,
    },
    {
      value: "startedAt",
      text: i18n.t("calls.grid.date") as string,
    },
    {
      value: "duration",
      text: i18n.t("calls.grid.duration") as string,
    },
    {
      value: "direction",
      text: i18n.t("calls.grid.direction") as string,
    },
    {
      value: "phone",
      text: i18n.t("calls.grid.phone") as string,
    },
    {
      value: "estimationValue",
      text: i18n.t("calls.grid.estimationValue") as string,
    },
    {
      value: "estimationDate",
      text: i18n.t("calls.grid.estimationDate") as string,
    },
    {
      value: "hasComments",
      text: i18n.t("calls.grid.comments") as string,
    },
  ];

  mounted() {
    const selected: string[] = this.$store.getters.getCallsSelectedGridColumns;

    this.selectedItems = this.list.filter((item) => {
      return selected.indexOf(item.value) >= 0;
    });
  }

  handleCheckboxChange(items: SelectModel[]) {
    this.selectedItems = items;
    this.$store.dispatch(
      StoreActionTypes.SET_CALLS_SELECTED_GRID_COLUMNS,
      items.map((item) => item.value)
    );
  }
}
