import Vue, { PropType } from "vue";
import { CallCriterionModel } from "@/services/types";

export const ListAnswerSwitcherWrapperProps = Vue.extend({
  props: {
    criterion: {
      type: Object as PropType<CallCriterionModel>,
      required: true,
    },
  },
});
