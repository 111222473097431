import mitt from "mitt";

type Events = {
  play: number;
  requestPlay: { id: number; time: number };
  setTime: { id: number; time: number };
  pause: number | null;
  togglePlay: number;
};

export const playerEventsEmitter = mitt<Events>();

export function emitPlayEvent(attachmentId: number) {
  playerEventsEmitter.emit("play", attachmentId);
}

export function emitRequestPlayEvent(data: { id: number; time: number }) {
  playerEventsEmitter.emit("requestPlay", data);
}

export function emitSetTimeEvent(data: { id: number; time: number }) {
  playerEventsEmitter.emit("setTime", data);
}

export function emitPauseEvent(attachmentId: number | null) {
  playerEventsEmitter.emit("pause", attachmentId);
}

export function emitTogglePlayEvent(attachmentId: number) {
  playerEventsEmitter.emit("togglePlay", attachmentId);
}
