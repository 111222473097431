import { render, staticRenderFns } from "./AudioPlaylist.vue?vue&type=template&id=5f25e1bc&scoped=true&"
import script from "./AudioPlaylist.vue?vue&type=script&lang=ts&setup=true&"
export * from "./AudioPlaylist.vue?vue&type=script&lang=ts&setup=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f25e1bc",
  null
  
)

export default component.exports