
import Vue from "vue";
import Component from "vue-class-component";
import { CommentModel } from "@/services/types";
import {
  IconDefinition,
  faComment,
  faComments,
} from "@fortawesome/free-regular-svg-icons";

@Component
export default class PlayerCommentsList extends Vue {
  private componentClass = "PlayerCommentsList";
  private commentIcon: IconDefinition = faComment;
  private commentsIcon: IconDefinition = faComments;

  private wrapperWidth = 0;

  get getCommentsList(): CommentModel[] {
    return this.$store.getters.getCommentsGroupedPlayerComments;
  }

  setPosition(list: CommentModel[]): number {
    const timestamp = list[0].timeOnTrack;
    const duration = this.$store.getters.getPlayerDuration;
    const position = (timestamp * this.wrapperWidth) / (duration * 1000);
    return Math.round(position);
  }

  getLabel(list: CommentModel[]): string {
    return list.length.toString();
  }

  getIcon(list: CommentModel[]): IconDefinition {
    if (list.length > 1) {
      return this.commentsIcon;
    } else {
      return this.commentIcon;
    }
  }

  onclick(list: CommentModel[]) {
    //this.$store.dispatch(StoreActionTypes.SET_COMMENTS_SELECTED_RECORD_ID_LIST, list.map(item => item.id))
    console.log(list);
  }

  mounted() {
    this.wrapperWidth = (
      this.$refs.wrapper as HTMLDivElement
    ).getBoundingClientRect().width;
  }
}
