
import Component from "vue-class-component";
import { IConfirmationComponentProps } from "./IConfirmationComponent";
import {
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/free-regular-svg-icons";

@Component({
  watch: {
    show: "handleShowStatusChange",
  },
})
export default class ConfirmationComponent extends IConfirmationComponentProps {
  private componentClass = "ConfirmationComponent";
  private modalCloseIcon: IconDefinition = faTimesCircle;
  private showModal = false;

  private closeEventsList: string[] = [
    "esc",
    "backdrop",
    "headerclose",
    "cancel",
  ];

  get getSubTitle(): string {
    return this.subtitle ? `: ${this.subtitle}` : "";
  }

  get showWithSubtitle(): boolean {
    return this.subtitle && this.subtitle.length > 0;
  }

  get getOkTitle() {
    return this.okTitle
      ? this.okTitle
      : (this.$i18n.t("actions.delete") as string);
  }

  get getCancelTitle() {
    return this.cancelTitle
      ? this.cancelTitle
      : (this.$i18n.t("actions.cancel") as string);
  }

  handleShowStatusChange() {
    this.showModal = this.show;
  }

  onHideListener(BvModalEvent) {
    if (this.closeEventsList.indexOf(BvModalEvent.trigger) >= 0) {
      this.onCancel();
    }
  }

  onOkListener(BvModalEvent) {
    BvModalEvent.preventDefault();
    this.onOk();
  }
}
