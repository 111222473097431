
import Vue from "vue";
import Component from "vue-class-component";
import { SelectModel, PlayBackSpeed } from "@/services/types";
import { StoreActionTypes } from "@/store/types";

@Component
export default class PlayerSpeedControls extends Vue {
  private componentClass = "PlayerSpeedControls";
  private showOptions = false;

  private playbackSpeedSelectOptions: SelectModel[] = [
    {
      value: "1",
      text: "1x",
    },
    {
      value: "1.25",
      text: "1.25x",
    },
    {
      value: "1.5",
      text: "1.5x",
    },
    {
      value: "2",
      text: "2x",
    },
  ];

  created() {
    window.addEventListener("click", this.onClickOutside);
  }

  beforeDestroy() {
    window.removeEventListener("click", this.onClickOutside);
  }

  get getPlayBackSpeed(): string {
    return this.$store.getters.getPlayerPlayBackSpeed.toString();
  }

  onValueClick() {
    this.showOptions = true;
    const idx = this.playbackSpeedSelectOptions.findIndex(
      (option) => option.value == this.getPlayBackSpeed
    );
    if (idx == this.playbackSpeedSelectOptions.length - 1) {
      this.setSelectedPlayBackSpeed(
        parseFloat(this.playbackSpeedSelectOptions[0].value) as PlayBackSpeed
      );
    } else {
      this.setSelectedPlayBackSpeed(
        parseFloat(
          this.playbackSpeedSelectOptions[idx + 1].value
        ) as PlayBackSpeed
      );
    }
  }

  onOptionClick(value: string) {
    this.setSelectedPlayBackSpeed(parseFloat(value) as PlayBackSpeed);
    this.showOptions = false;
  }

  setSelectedPlayBackSpeed(playBackSpeed: PlayBackSpeed) {
    this.$store.dispatch(
      StoreActionTypes.SET_PLAYER_PLAY_BACK_SPEED,
      playBackSpeed
    );
  }

  hasActionClass(value: string): string {
    return this.$store.getters.getPlayerPlayBackSpeed == value ? "active" : "";
  }

  get getReversePlaybackSpeedSelectOptions() {
    return this.playbackSpeedSelectOptions.reverse();
  }

  onClickOutside(event: MouseEvent) {
    if (
      !(this.$refs.wrapper as HTMLDivElement).contains(
        event.target as HTMLDivElement
      )
    ) {
      this.showOptions = false;
    }
  }
}
