
import { Component, Prop, Vue } from "vue-property-decorator";
import { faEllipsisV, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import * as utils from "./../../../services/utils";
import { StoreActionTypes } from "@/store/types";
import EditSelectedCommentForm from "@/components/lib/commentInput/EditSelectedCommentForm.vue";
import { CommentModel } from "@/services/types/entities/comments";
import { emitRequestPlayEvent } from "@/components/player2/audioPlayerEvents";

@Component({
  components: {
    EditSelectedCommentForm,
  },
})
export default class CommentView extends Vue {
  @Prop() comment: CommentModel;
  @Prop() editBtnListener: Function;
  @Prop() deleteBtnListener: Function;

  private componentClass = "CommentView";
  private ellipsis: IconDefinition = faEllipsisV;

  get commentPosition() {
    return this.comment.data.position;
  }

  get commentAuthorName() {
    return this.comment.author.name.replace("Фамилия", "").trim();
  }

  getCommentDate() {
    return utils.renderDateFromString(this.comment.createdAt);
  }

  handleEditItemClick() {
    this.editBtnListener(this.comment.id);
  }

  handleDeleteItemClick() {
    this.deleteBtnListener(this.comment.id);
  }

  getPlayerTime() {
    return this.comment.data.position.timeOnTrack !== null
      ? utils.renderDurationWithDots(
          this.comment.data.position.timeOnTrack * 1000
        )
      : "";
  }

  handlePlayerTimeClick() {
    emitRequestPlayEvent({
      id: this.comment.data.position.attachment,
      time: this.comment.data.position.timeOnTrack,
    });
    // if (
    //   this.comment.data.position.timeOnTrack ==
    //   this.$store.getters.getPlayerNewCurrentTime
    // ) {
    //   this.$store.dispatch(
    //     StoreActionTypes.SET_PLAYER_NEW_CURRENT_TIME,
    //     this.comment.data.position.timeOnTrack > 1
    //       ? this.comment.data.position.timeOnTrack - 0.1
    //       : 0
    //   );
    // } else {
    //   this.$store.dispatch(
    //     StoreActionTypes.SET_PLAYER_NEW_CURRENT_TIME,
    //     this.comment.data.position.timeOnTrack
    //   );
    // }
  }

  get getAuthorAvatar() {
    return this.comment.author.name.split("")[0].toUpperCase();
  }

  get showEditSelectedCommentsForm(): boolean {
    return (
      this.$store.getters.getCommentsSelectedCommentId &&
      this.$store.getters.getCommentsSelectedCommentId == this.comment.id
    );
  }
}
