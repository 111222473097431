var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"audio-player d-flex align-items-end"},[_c('div',{staticClass:"flex-grow-1 mr-3"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('div',{staticClass:"__name",class:{
            active: _setup.props.id === _setup.usePlayerStore().lastPlayed.id,
          }},[_vm._v(" ID: "+_vm._s(_vm.id)+" ")]),_c('div',{staticClass:"d-inline-flex align-items-center",staticStyle:{"gap":"6px"}},[_c('div',{staticClass:"__duration"},[_vm._v(" "+_vm._s(_setup.formattedCurrentTime)+" / "+_vm._s(_setup.formattedDuration)+" ")])])]),_c(_setup.WavesurferPlayer,{attrs:{"src":_vm.src,"audio-element":_setup.audio},on:{"ready":_setup.handleCanPlay}})],1),_c('div',{staticClass:"d-inline-flex align-items-center",staticStyle:{"gap":"6px","margin-bottom":"30px"}},[_c(_setup.ActionIconButton,{staticClass:"__rewind-btn",attrs:{"title":"-10 сек","icon":_setup.faBackward,"listener":() => {
            _setup.rewindAudio(-10);
          }}}),(!_setup.isReady)?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"width":"24px"}},[_c('b-spinner',{attrs:{"small":"","variant":"secondary"}})],1):_c(_setup.ActionIconButton,{staticClass:"__play-btn",attrs:{"icon":_setup.playIcon,"listener":_setup.handlePlayPauseBtnClick}}),_c(_setup.ActionIconButton,{staticClass:"__rewind-btn",attrs:{"title":"+10 сек","icon":_setup.faForward,"listener":() => {
            _setup.rewindAudio(10);
          }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }