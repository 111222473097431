
import Component from "vue-class-component";
import { DateIntervalsType, SelectModel } from "@/services/types";
import { ISelectDateIntervalProp } from "./ISelectDateInterval";
import * as utils from "@/services/utils";

@Component
export default class SelectDateInterval extends ISelectDateIntervalProp {
  private componentClass = "SelectDateInterval";

  private selectOptions: SelectModel[] = [
    {
      value: "day",
      text: utils.getDateInterval("day"),
    },
    {
      value: "week",
      text: utils.getDateInterval("week"),
    },
    {
      value: "month",
      text: utils.getDateInterval("month"),
    },
    // {
    //     value: 'custom',
    //     text: utils.getDateInterval('custom')
    // }
  ];

  isActive(value: string) {
    return this.value == value;
  }

  get getTitle(): string {
    return utils.getDateInterval(this.value as DateIntervalsType);
  }
}
