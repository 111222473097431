import { computed } from "vue";
import { getStore } from "@/store/getStore";

const playbackSpeed = computed(() => getStore().getters.getPlayerPlayBackSpeed);
const volume = computed(() => getStore().getters.getPlayerVolume);

export const useGlobalPlayerSettings = () => {
  return {
    playbackSpeed,
    volume,
  };
};
