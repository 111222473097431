import { render, staticRenderFns } from "./CommentView.vue?vue&type=template&id=260554da&scoped=true&"
import script from "./CommentView.vue?vue&type=script&lang=ts&"
export * from "./CommentView.vue?vue&type=script&lang=ts&"
import style0 from "./CommentView.vue?vue&type=style&index=0&id=260554da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260554da",
  null
  
)

export default component.exports