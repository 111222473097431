var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[_vm.componentClass]},[_c('div',{class:`${_vm.componentClass}__label`},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:`${_vm.componentClass}__form-input`},[_c('div',{class:`${_vm.componentClass}__date-filter`},[_c('div',{class:`${_vm.componentClass}__date-filter-item`},[_c('b-form-datepicker',{class:{ active: !!_vm.dates.from },attrs:{"value":_vm.dates.from,"locale":"ru","start-weekday":"1","date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },"label-no-date-selected":"","reset-button":"","label-reset-button":_vm.$t('actions.clear'),"close-button":"","label-close-button":_vm.$t('actions.close')},on:{"input":function($event){return _vm.listenerFrom($event)}}})],1),_c('div',{class:`${_vm.componentClass}__date-filter-item`},[_c('div',{class:`${_vm.componentClass}__date-filter-divider`},[_c('font-awesome-icon',{staticClass:"dividerIcon",attrs:{"icon":_vm.dividerIcon}})],1)]),_c('div',{class:`${_vm.componentClass}__date-filter-item`},[_c('b-form-datepicker',{class:{ active: !!_vm.dates.to },attrs:{"value":_vm.dates.to,"locale":"ru","start-weekday":"1","date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },"label-no-date-selected":"","reset-button":"","label-reset-button":_vm.$t('actions.clear'),"close-button":"","label-close-button":_vm.$t('actions.close')},on:{"input":function($event){return _vm.listenerTo($event)}}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }