import Vue, { PropType } from "vue";
import { SelectModel } from "@/services/types";

export const DefaultSelectProps = Vue.extend({
  props: {
    options: {
      type: Array as PropType<SelectModel[]>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    selectedItem: {
      type: String,
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    onChange: {
      required: true,
      type: Function as PropType<(selected: string) => void>,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
