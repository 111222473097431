
import Component from "vue-class-component";
import { CallEstimationType, SelectModel } from "@/services/types";
import { ISelectCalls } from "./ISelectCalls";
import * as utils from "@/services/utils";
import { i18n } from "@/i18n/index";

@Component
export default class SelectCallsFilter extends ISelectCalls {
  private componentClass = "SelectEstimationFilter";

  private estimationInitialCallsOptions = [
    {
      value: 0,
    },
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
  ];

  initialCallsText(value) {
    return value == 1
      ? i18n.t("calls.initialCalls.single")
      : i18n.t("calls.initialCalls.more");
  }

  get emptyCallsText() {
    return i18n.t("calls.initialCalls.empty");
  }

  get callsTitle() {
    return i18n.t("calls.initialCalls.title");
  }

  isActive(value: number) {
    return value == this.value;
  }

  onItemSelect(selected: number) {
    return this.onSelect(selected);
  }
}
