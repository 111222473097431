
import Component from "vue-class-component";
import { ActionButtonProps } from "./IActionButton";

@Component
export default class ActionButton extends ActionButtonProps {
  private componentClass = "ActionButton";

  get btnVariant(): string {
    return this.outline ? `outline-${this.variant}` : this.variant;
  }
}
