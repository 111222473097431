
import Component from "vue-class-component";
import DefaultSelect from "@/components/lib/select/DefaultSelect.vue";
import { SelectModel, CriterionTypeCode } from "@/services/types";
import { ISelectCriterionTypeFilterProp } from "./ISelectCriterionTypeFilter";
import * as utils from "@/services/utils";
import { i18n } from "@/i18n/index";

@Component({
  components: {
    DefaultSelect,
  },
})
export default class SelectCriterionTypeFilter extends ISelectCriterionTypeFilterProp {
  private componentClass = "SelectCriterionTypeFilter";

  private statusSelectOptions: SelectModel[] = [
    {
      value: "ALL",
      text: i18n.t("criterions.forms.criterionType.all") as string,
    },
    {
      value: "CUSTOM",
      text: utils.getCriterionTypeCode("CUSTOM"),
    },
    {
      value: "PROHIBITION",
      text: utils.getCriterionTypeCode("PROHIBITION"),
    },
    {
      value: "REQUIREMENT",
      text: utils.getCriterionTypeCode("REQUIREMENT"),
    },
  ];

  get getTitle() {
    return utils.getCriterionTypeCode(this.value as CriterionTypeCode);
  }

  isActive(value: string) {
    if (value != null) {
      return value == this.value;
    } else {
      return true;
    }
  }

  onItemSelect(selected: string) {
    if (selected == "ALL") {
      this.onSelect("null");
    } else {
      return this.onSelect(selected);
    }
  }
}
