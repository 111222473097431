import Vue from "vue";
import { PropType } from "vue";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { ColorTheme } from "@/services/types";

export interface ActionIconBtnText {
  text: string;
  color: ColorTheme;
}

export const ActionIconButtonProps = Vue.extend({
  props: {
    icon: {
      type: Object as PropType<IconDefinition>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    listener: {
      type: Function,
      required: true,
    },
    text: {
      type: Object as PropType<ActionIconBtnText>,
      required: false,
    },
  },
});
