
import Component from "vue-class-component";
import { DefaultSelectProps } from "./IDefaultSelect";

@Component
export default class DefaultSelect extends DefaultSelectProps {
  private componentClass = "DefaultSelect";

  listener(event: string) {
    this.onChange(event);
  }
}
