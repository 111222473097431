
import Component from "vue-class-component";
import Vue from "vue";
import ActionButton from "./../button/ActionButton.vue";
import FormInput from "./../input/FormInput.vue";
import * as utils from "@/services/utils";
import { renderDurationWithDots } from "@/services/utils";
import {
  faClock,
  faRotateRight,
  faTimes,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";
import ActionIconButton from "@/components/lib/button/ActionIconButton.vue";
import { UpdateEntityCommentRequestDto } from "@/services/api/entities/types";
import { CommentModel } from "@/services/types/entities/comments";
import { usePlayerStore } from "@/pinia/player";

type TimestampData = {
  id: number;
  time: number;
};

@Component({
  methods: { renderDurationWithDots },
  components: {
    ActionIconButton,
    ActionButton,
    FormInput,
  },
  watch: {
    "$store.getters.getCommentsSelectedCommentId": "watchSelectedCommentId",
    "$store.getters.getPlayerCurrentTime": "watchPlayerCurrentTime",
  },
})
export default class EditSelectedCommentForm extends Vue {
  private componentClass = "EditSelectedCommentForm";
  private value = "";
  private currentTimeValue = "00:00";
  private clearIcon: IconDefinition = faTimes;

  //#region Timestamp

  timestampEnabled = true;
  timestampData: TimestampData = {
    id: null,
    time: null,
  };
  timeIcon: IconDefinition = faClock;
  updateIcon: IconDefinition = faRotateRight;

  get playerTimestampData() {
    return usePlayerStore().lastPlayed;
  }

  get hasValidTimestamp() {
    return this.timestampData.id !== null && this.timestampData.time !== null;
  }

  toggleTimestamp() {
    this.timestampEnabled = !this.timestampEnabled;
  }

  updateTimestamp() {
    if (this.playerTimestampData.id) {
      this.timestampData = JSON.parse(JSON.stringify(this.playerTimestampData));
    }
  }

  //#endregion

  private currentTimeValueIsValid = true;

  mounted() {
    if (this.$store.getters.getCommentsSelectedCommentId) {
      this.renderForm();
    }
  }

  async handleEditBtnClick() {
    if (this.validateCurrentTimeValue()) {
      await this.$store.dispatch(
        StoreActionTypes.SET_COMMENTS_EDIT_SELECTED_COMMENT,
        this.createEditCommentRequestModel()
      );
    }
  }

  createEditCommentRequestModel(): UpdateEntityCommentRequestDto {
    return {
      data: {
        text: this.value,
        position:
          this.timestampEnabled && this.hasValidTimestamp
            ? {
                attachment: this.timestampData.id,
                timeOnTrack: Math.round(this.timestampData.time),
              }
            : {
                attachment: null,
                timeOnTrack: null,
              },
      },
    };
  }

  handleCancelBtnClick() {
    this.value = "";
    this.currentTimeValue = "00:00";
    this.$store.dispatch(
      StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT,
      false
    );
    this.$store.dispatch(StoreActionTypes.SET_COMMENTS_SELECTED_COMMENT_CLEAR);
  }

  watchSelectedCommentId(id: number, oldId: number) {
    if (id && id != oldId) {
      this.renderForm();
    }
  }

  watchPlayerCurrentTime(newTime: number, oldTime: number) {
    if (newTime != oldTime) {
      this.currentTimeValue = utils.renderDurationWithDots(newTime * 1000);
    }
  }

  renderForm() {
    const comment: CommentModel =
      this.$store.getters.getCommentsSelectedComment;

    this.timestampData = {
      id: comment.data.position.attachment || null,
      time: comment.data.position.timeOnTrack || null,
    };

    this.value = comment.data.text;
    this.$store.dispatch(
      StoreActionTypes.SET_COMMENTS_HAS_NOT_SAVED_COMMENT,
      true
    );
  }

  handleCurrentTimeInputEvent(text: string) {
    this.currentTimeValueIsValid = true;
    this.currentTimeValue = text;
  }

  handleClearIconClick() {
    this.currentTimeValueIsValid = true;
    this.currentTimeValue = "00:00";
  }

  get showClearIcon() {
    return (
      this.currentTimeValue.length > 0 &&
      utils.getSecondsFromMmSsString(this.currentTimeValue)
    );
  }

  validateCurrentTimeValue(): boolean {
    const duration = this.$store.getters.getPlayerDuration;

    if (duration == 0) {
      return true;
    }

    const timeOnTrack =
      this.currentTimeValue.length > 0
        ? utils.getSecondsFromMmSsString(this.currentTimeValue)
        : 0;

    if (timeOnTrack > duration) {
      this.currentTimeValueIsValid = false;
      return false;
    } else {
      this.currentTimeValueIsValid = true;
      return true;
    }
  }

  get currentTimeValueFormInputValidation(): boolean {
    return this.currentTimeValueIsValid ? null : false;
  }
}
