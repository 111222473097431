
import Component from "vue-class-component";
import { CollapseAreaProps } from "./ICollapseArea";
import {
  IconDefinition,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { StoreMutationTypes } from "@/store/types";

@Component
export default class CollapseArea extends CollapseAreaProps {
  private componentClass = "CollapseArea";
  private show = true;
  private headerIcon: IconDefinition = faChevronDown;

  mounted() {
    this.show = !this.collapsed;
  }

  get getShow() {
    return this.$store.getters.getTopCollapseOpened;
  }

  setShow(value) {
    this.show = value;
  }

  get setVisible(): string {
    return this.show ? "visible" : "";
  }
}
