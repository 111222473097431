
import Component from "vue-class-component";
import { BaseEntityModel } from "@/services/types";
import { ISelectCategoryProp } from "./ISelectCategory";
import { StoreActionTypes } from "@/store/types";
import FormInput from "@/components/lib/input/FormInput.vue";

@Component({
  components: {
    FormInput,
  },
})
export default class SelectCategory extends ISelectCategoryProp {
  private componentClass = "SelectCategory";
  private searchValue = "";

  mounted() {
    this.$store.dispatch(StoreActionTypes.SET_CATEGORIES_FULL_LIST);
  }

  get getItemList(): BaseEntityModel[] {
    return this.$store.getters.getCategoriesFullList;
  }

  get getFilteredItemList(): BaseEntityModel[] {
    if (this.searchValue.length >= 2) {
      return this.getItemList.filter(
        (item) =>
          item.name
            .trim()
            .toLowerCase()
            .indexOf(this.searchValue.trim().toLowerCase()) >= 0
      );
    } else {
      return this.getItemList;
    }
  }

  isActive(id: number) {
    return this.value == id;
  }

  onSearchChange(search: string) {
    this.searchValue = search;
  }

  get getTitle(): string {
    if (this.getItemList.find((item) => item.id == this.value)) {
      return this.getItemList.find((item) => item.id == this.value).name;
    } else {
      return this.$i18n.t("criterions.select.title") as string;
    }
  }
}
