import { DateFilterModel } from "@/services/types";
import Vue, { PropType } from "vue";

export const IDateFilterProps = Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    dates: {
      type: Object as PropType<DateFilterModel>,
      required: true,
    },
    onChange: {
      type: Function as PropType<(dates: DateFilterModel) => void>,
      required: true,
    },
  },
});
