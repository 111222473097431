import Vue, { PropType } from "vue";

export const IDurationFilterProps = Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    minDuration: {
      type: String,
      required: true,
    },
    maxDuration: {
      type: String,
      required: true,
    },
    minDurationListener: {
      required: true,
      type: Function as PropType<(text: string) => void>,
    },
    maxDurationListener: {
      required: true,
      type: Function as PropType<(text: string) => void>,
    },
  },
});
