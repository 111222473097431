import Vue, { PropType } from "vue";
import { BaseEntityModel } from "@/services/types";

export const ISelectManagersMultiple = Vue.extend({
  props: {
    onSelect: {
      type: Function as PropType<(selectedItems: BaseEntityModel[]) => void>,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<BaseEntityModel[]>,
      required: true,
    },
    itemsList: {
      type: Array as PropType<BaseEntityModel[]>,
      required: true,
    },
  },
});
