
import Component from "vue-class-component";
import { IconDefinition, faMinus } from "@fortawesome/free-solid-svg-icons";
import { IDateFilterProps } from "./IDateFilter";

@Component
export default class DateFilter extends IDateFilterProps {
  private componentClass = "DateFilter";

  private dividerIcon: IconDefinition = faMinus;

  listenerFrom(event: string) {
    this.onChange({
      from: event,
      to: this.dates.to,
    });
  }

  listenerTo(event: string) {
    this.onChange({
      from: this.dates.from,
      to: event,
    });
  }
}
