
import Component from "vue-class-component";
import { CallEstimationType, SelectModel } from "@/services/types";
import { ISelectEstimation } from "./ISelectEstimation";
import * as utils from "@/services/utils";
import { i18n } from "@/i18n/index";

@Component
export default class SelectEstimationFilter extends ISelectEstimation {
  private componentClass = "SelectEstimationFilter";

  private estimationSelectOptions: SelectModel[] = [
    {
      value: "ALL",
      text: i18n.t("calls.estimation.all") as string,
    },
    {
      value: "withEstimation",
      text: utils.getCallEstimation("withEstimation"),
    },
    {
      value: "withoutEstimation",
      text: utils.getCallEstimation("withoutEstimation"),
    },
  ];

  get getTitle() {
    return utils.getCallEstimation(this.value as CallEstimationType);
  }

  isActive(value: string) {
    if (value != null) {
      return value == this.value;
    } else {
      return true;
    }
  }

  onItemSelect(selected: string) {
    if (selected == "ALL") {
      this.onSelect("null");
    } else {
      return this.onSelect(selected);
    }
  }
}
