
import Component from "vue-class-component";
import { FormInputProps } from "./IFormInput";

@Component
export default class FormInput extends FormInputProps {
  private componentClass = "FormInput";

  listener(event: string) {
    this.onChange(event);
  }

  onKeyUp() {
    if (this.onEnter !== undefined) {
      this.onEnter();
    }
  }

  get isValid(): string {
    if (this.validated == null) {
      return "";
    } else {
      return this.validated ? "validated" : "not-valid";
    }
  }
}
